.pagination-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  font-family: var(--default-font-family);
  user-select: none;

  .pagination {
    display: flex;
    border: 1px solid var(--silver);
    border-radius: 4px;
    overflow: auto;
    background-color: var(--pure-white);

    .pagination-item {
      color: var(--midnight-gray);
      background: var(--pure-white);
      min-width: 36px;
      min-height: 36px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      list-style: none;
      transition: transform 0.3s;
      padding-inline: 0.4rem;

      &:hover {
        color: var(--pure-white);
        background: var(--primary-color);
      }
    }

    .pagination-item:not(:last-child) {
      border-right: 1px solid var(--silver);
    }
    .active-page {
      color: var(--pure-white);
      background: var(--primary-color);
    }

    .active-page .pagination-link {
      color: var(--pure-white);
    }

    .next-prev-symbol {
      font-size: 1.5rem;
    }
  }
}

.button-disabled {
  pointer-events: none;
  cursor: not-allowed;

  .pagination-link {
    color: var(--light-steel);
  }
}

/* Media queries */
@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;

    .pagination-item:has(.next-prev-symbol) {
      display: none;
    }
  }
}
