// --> Created-by : thakkarshrey10
// --> Dated : 27/04/2024

// These are the global styles for InputOTP component from antd-input-otp.
// This is used as an RPPS number input component.
// These styles are imported in the main index.scss file which are available globally.

.rpps-input-wrapper {
  gap: 0.2em !important;
  .rpps-input {
    background-color: var(--alice-blue);
    color: var(--prusian-blue);
    width: 25px !important;
    height: 35px !important;
    font-size: 12px;
    font-family: var(--font-family-nunito);
    padding: 0;
    border-radius: 8.5px;
    border: none;

    &:focus-visible {
      border: 1px solid var(--primary-color) !important;
      background-color: var(--pure-white);
      color: var(--primary-color);
    }
    &:active {
      border: 1px solid var(--primary-color) !important;
      background-color: var(--pure-white);
      color: var(--primary-color);
    }
    &[value]:not([value='']) {
      border: 1px solid var(--primary-color) !important;
      background-color: var(--pure-white);
      color: var(--primary-color);
    }
  }
}
