.ant-radio-inner {
  border: 1px solid var(--silver) !important;
  background-color: var(--pure-white) !important;
}
.ant-radio-inner::after {
  --default-checkbox-size: 16;
  content: '';
  background-color: var(--prusian-blue) !important;
  width: calc(1.5px * var(--default-checkbox-size)) !important;
  height: calc(1.5px * var(--default-checkbox-size)) !important;
  margin-block-start: calc((1.5px * var(--default-checkbox-size)) / -2) !important;
  margin-inline-start: calc((1.5px * var(--default-checkbox-size)) / -2) !important;
}
