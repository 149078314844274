.faq-collapse {
  margin-top: 20px;
  .ant-collapse {
    border: none;
  }
  .ant-collapse-header {
    background-color: var(--silver-sand);
  }
  .collapse-label {
    background-color: var(--cerulean);
    padding: 8px 8px 4px 8px;
    border-radius: 20px 20px 0px 0px;
    height: 35px;
  }
  .collapse-content {
    background-color: var(--milk-white);
    padding: 8px 8px 4px 8px;
    border-radius: 20px 20px 0px 0px;
    height: 35px;
  }
}
