.case-layout-wrapper {
  background-color: var(--ghost-white);
  padding-bottom: 52px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  min-height: 100vh;

  .settings-content-wrapper {
    border-radius: 4px;
    box-shadow: var(--default-box-shadow);
    width: 100%;
    aspect-ratio: 1;
  }
}
