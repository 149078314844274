.verified-badge-container {
  position: relative;
  display: inline-block;
  width: fit-content;

  .verified-badge-wrapper {
    border: 2px solid var(--cerulean);
  }
  .verified-badge-tick {
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%); /* Center the tick horizontally */
  }
}
