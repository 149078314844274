// --> Created-by : thakkarshrey10
// --> Dated : 27/04/2024

// These are the global styles for MultiSearchable select component from Atnd.
// They are applied because of the restriction of style changes in antd theme.js.
// These styles are imported in the main index.scss file which are available globally.

.ant-select-selection-item {
  border-radius: 8px !important;
  padding: 6px 12px !important;
  color: var(--dark-cyan);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
}

.ant-select-item-option-state {
  color: var(--dark-cyan) !important;
}
