.main-layout-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--silver-sand);
  position: relative;
  z-index: 0;
  height: 100vh;
  overflow: hidden;

  .sidebar-button {
    font-size: 1rem;
    background: var(--silver);
    position: fixed;
    z-index: 1053;
    box-shadow: var(--default-box-shadow);
    width: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.4s;
  }

  .trigger-collapsed-zero {
    left: 90px;
    top: 70px;
  }

  .trigger-collapsed-nonzero {
    left: 240px;
    top: 70px;
  }

  .toggle-left {
    transform: rotate(-180deg);
    transition: all 0.4s;
  }

  .toggle-right {
    transform: rotate(0deg);
    transition: all 0.4s;
  }

  .sidebar-layout-container {
    position: relative;
  }

  .main-layout-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.4s;
    height: 100%;
    overflow: hidden;
  }

  .outlet-container {
    overflow-y: auto;
  }

  .main-layout-wrapper-expand {
    width: calc(100% - 110px);
  }

  .main-layout-wrapper-collapse {
    width: calc(100% - 260px);
  }
}

/* Media queries */
@media (max-width: 768px) {
  .main-layout-container {
    .main-layout-wrapper {
      width: 100%;
    }

    .trigger-collapsed-zero {
      position: fixed;
      left: 15px;
      top: 20px;
    }
    .trigger-collapsed-nonzero {
      position: fixed;
      left: 240px;
      top: 70px;
    }
  }
}
