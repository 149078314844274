.primary-layout-wrapper {
  background-color: var(--ghost-white);
  z-index: 0;
  min-height: 100px;
  position: relative;

  .settings-sidebar-button {
    font-size: 1rem;
    background: var(--silver);
    position: fixed;
    z-index: 1053;
    box-shadow: var(--default-box-shadow);
    width: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: none;
    cursor: pointer;
    transition: all 0.3s;
  }

  .settings-trigger-collapsed-zero {
    left: 15px;
    top: 20px;
  }

  .settings-trigger-collapsed-nonzero {
    left: 220px;
    top: 20px;
  }

  .settings-toggle-left {
    transform: rotate(-180deg);
    transition: all 0.3s;
  }

  .settings-toggle-right {
    transform: rotate(0deg);
    transition: all 0.3s;
  }

  .settings-content-wrapper {
    border-radius: 4px;
    box-shadow: var(--default-box-shadow);
    width: 75%;
    overflow-y: auto;
  }
}

/* Media queries */
@media (max-width: 991px) {
  .primary-layout-wrapper {
    z-index: 0;
    .settings-content-wrapper {
      width: 65%;
    }
  }
}

@media (max-width: 768px) {
  .primary-layout-wrapper {
    .settings-sidebar-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .settings-content-wrapper {
      width: 100%;
    }
  }
}
