.blue-shage-layout-container {
  background-image: url('../../../../public/assets//images/blue-shade-layout-bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  min-height: 100dvh;
  margin-inline: auto;
  padding: 2% 4% 5% 2%;
}
