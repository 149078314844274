:root {
  --default-sidebar-width-open: 260px;
  --default-sidebar-width-collapsed: 110px;
  --default-indicator-width: 189px;
}

.sidebar-container {
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  left: 0;
  padding: 2rem 1rem;
  box-shadow: var(--default-box-shadow);
  min-height: 100vh;
  font-family: 'Nunito Sans';
  z-index: 1052;
  transition: all 0.4s;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.sidebar-wrapper {
  padding: 0 0.9rem;
  position: relative;
  height: calc(100vh - 220px);
}

.sidebar-widget-container {
  position: sticky;
  left: 0;
  bottom: 20px;
  z-index: 1052;
  .sidebar-widget-card {
    position: absolute;
    left: 20px;
    bottom: 70px;
    border-radius: 12px;
    background: var(--pure-white);
    box-shadow: var(--default-box-shadow);
    width: 85%;
    padding: 10px;
    .sidebar-widget-btn {
      padding: 0;
      font-size: 14px;
      color: black;
    }
  }
  .show-sidebar-widget-card {
    opacity: 1;
    visibility: visible;
    transition: all 0.8s;
    max-height: 500px;
    z-index: 10;
  }
  .hide-sidebar-widget-card {
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s;
    max-height: 0px;
  }
  .sidebar-widget {
    background: var(--deep-teal);
    box-shadow: 0px 1.64px 3.28px 0px var(--midnight-blue);
    border: none;
    margin: 0 0.9rem;
    border-radius: 12px;
    padding: 0.9rem;
    cursor: pointer;

    .sidebar-widget-header-icon {
      background-color: var(--cerulean);
      padding: 8px;
      border-radius: 10px;
      height: 35px;
    }

    .need-help-title {
      white-space: nowrap;
    }

    .toggle-left {
      transform: rotate(-180deg);
      transition: all 0.3s;
    }

    .toggle-right {
      transform: rotate(0deg);
      transition: all 0.3s;
    }
  }
}

.sidebar-open {
  width: var(--default-sidebar-width-open);
}

.sidebar-collapsed {
  width: var(--default-sidebar-width-collapsed);
}

.sidebar-collapsed .sidebar-wrapper {
  overflow: initial;
}

.sidebar-open .sidebar-wrapper {
  overflow: auto;
}

.sidebar-collapsed .sidebar-widget {
  visibility: hidden;
  opacity: 0;
}
.sidebar-open .sidebar-widget {
  opacity: 1;
  visibility: visible;
}

.navigation-list {
  margin: 0;
  padding: 0rem 0.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 0.4rem;
}

.navigation-item-list {
  list-style: none;
  width: 100%;
  text-decoration: none;
  color: var(--aqua-mist);
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
  display: flex;
  align-items: center;
}

.navigation-item-list-white {
  font-weight: 400;
}

.navigation-item-list-primary {
  color: var(--pure-white);
  background-color: var(--primary-dark-color);
}

.navigation-item-link {
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;

  .navigation-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
  }

  .navigation-label {
    white-space: nowrap;
  }

  .navigation-label-hidden {
    visibility: hidden;
    transition: all 0.4s;
  }
}

// .navigation-item-link > * {
//   pointer-events: none;
// }

.navigation-item-link-white-bg {
  font-weight: 700;
}

.navigation-item-list:hover .navigation-item-link-primary-bg {
  background-color: var(--primary-dark-color);
  font-weight: 700;
  border-radius: 4px;
}

.navigation-item-list-collapsed:hover .navigation-item-link-primary-bg {
  background-color: var(--primary-dark-color);
  font-weight: 700;
  border-radius: 4px;
}

.navigation-item-list:hover .navigation-item-link-white-bg {
  background-color: var(--primary-dark-color);
  color: var(--pure-white);
  font-weight: 700;
  border-radius: 4px;
}

.navigation-item-list-collapsed:hover .navigation-item-link-white-bg {
  background-color: var(--primary-dark-color);
  color: var(--primary-color);
  font-weight: 700;
  border-radius: 4px;
}

.menu-group-title {
  font-size: 0.6rem;
  text-transform: uppercase;
  color: var(--aqua-mist);
  transition: ease-in 0.4s;
  width: 100%;
  pointer-events: none;
}

.menu-group-title-collapsed {
  text-align: center;
}

.indicator {
  position: absolute;
  height: 36px;
  top: 0;
  left: 0;
  z-index: 0.5;
  width: var(--default-indicator-width);
  margin: 0rem 0.9rem;
  transition: all ease 0.4s;

  .indicator-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-dark-color);
    color: var(--pure-white);
    border-radius: 4px;
    transition: all ease 0.4s;
  }
}

/* Media queries */
@media (max-width: 768px) {
  .sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
  }
  .sidebar-collapsed {
    padding: 0;
    width: 0;

    .sidebar-wrapper {
      padding: 0;
    }

    .navigation-list {
      display: none;
    }
    .indicator {
      max-width: 0;
    }
  }
}
