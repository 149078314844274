.settings-sidebar-container {
  position: relative;
  // width: 260px;
  // min-width: 260px;
  transition: all 0.3s;
  // width: 25%;

  .settings-sidebar-wrapper {
    background-color: var(--pure-white);
    box-shadow: var(--default-box-shadow);
    border-radius: 4px;
    position: sticky;
    top: 6rem;
    left: 0;
    height: fit-content;
    padding: 10px;

    .settings-navigation-list {
      margin: 0;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 0.4rem;

      .settings-navigation-item-list {
        list-style: none;
        width: 100%;
        z-index: 3;
        text-decoration: none;
        color: var(--midnight-gray);
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
        user-select: none;

        .toggle-down {
          transform: rotate(0deg);
          transition: transform 0.3s ease;
        }

        .toggle-up {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }

        &:hover {
          color: var(--primary-color);
          background-color: var(--pale-blue);
        }

        .settings-navigation-label {
          white-space: nowrap;
          transition: all 0.4s;
        }
      }

      .settings-sub-navigation-container {
        overflow: hidden;
        opacity: 0;
        max-height: 0;
        transition:
          max-height 0.8s ease,
          opacity 0.8s ease;

        .settings-sub-navigation-item-list {
          color: var(--prusian-blue);
          list-style: none;
          width: 100%;
          z-index: 3;
          text-decoration: none;
          color: var(--midnight-gray);
          cursor: pointer;
          font-weight: 600;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-inline: 1rem;
          margin-inline: 1rem;
          user-select: none;

          &:hover {
            color: var(--prusian-blue);
            background-color: var(--pale-blue);
            border-left: 4px solid var(--prusian-blue);
          }
        }
      }

      .settings-sub-navigation-container.settings-hide-sub-navigation-container {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.2s ease all;
      }

      .settings-sub-navigation-container.settings-show-sub-navigation-container {
        opacity: 1;
        max-height: 100rem;
      }

      @for $i from 0 to 20 {
        .settings-navigation-item-list.active#{$i} {
          background-color: var(--pale-blue);
          color: var(--primary-color);
        }

        .settings-sub-navigation-item-list.active#{$i} {
          padding-left: 1rem;
          border-left: 4px solid var(--prusian-blue);
          color: var(--prusian-blue);
          background-color: var(--pale-blue);
        }
      }
    }
  }
}

.settings-sidebar-open {
  // width: 260px;
  // min-width: 260px;
  width: 25%;
}

.settings-sidebar-collapsed {
  width: 0;
  min-width: 0;
}

/* Media queries */
@media (max-width: 991px) {
  .settings-sidebar-open {
    width: 35%;
  }
}

@media (max-width: 768px) {
  .settings-sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1052;
    min-height: 100vh;
    background-color: var(--pure-white);
    box-shadow: var(--default-box-shadow);

    .settings-sidebar-wrapper {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      top: 3rem;
    }
  }
  .settings-sidebar-collapsed {
    .settings-sidebar-wrapper {
      .settings-navigation-list {
        display: none;
      }
      .settings-indicator {
        max-width: 0;
      }
    }
  }
  .settings-sidebar-open {
    width: 260px;
    min-width: 260px;
  }
}
