/* Media queries */
@media (max-width: 768px) {
  .ant-picker-dropdown {
    .ant-picker-range-wrapper {
      .ant-picker-panel-container {
        display: flex !important;
        flex-direction: column !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .ant-picker-panel-layout {
    flex-direction: column;
    .ant-picker-presets {
      height: 155px;
      max-width: none !important;
    }
    .ant-picker-panels {
      flex-direction: column;
    }
  }
}
