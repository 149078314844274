.disabled-input-field-replica {
  box-sizing: border-box;
  margin: 0;
  padding: 10px 15px;
  color: var(--deep-navy-blue);
  font-size: 12px;
  list-style: none;
  font-family: Nunito Sans;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 12px;
  transition: all 0.2s;
  background-color: var(--grayish-blue);
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--pale-sky);
}
