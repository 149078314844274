.auth-container {
  width: 100%;
  height: 100vh;

  .auth-carousel-container {
    background-image: url('../../../../public/assets/images/auth-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;

    .carousel-card {
      position: absolute;
      inset: 0;
      opacity: 0;
      transform: scale(0.8);
      transition: all 0.5s;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .carousel-card-active {
      opacity: 1;
      transform: scale(1);
      transition: all 0.5s;
      pointer-events: visible;
    }

    .carousel-indicator-container {
      display: flex;
      gap: 0.5rem;
      position: absolute;
      left: 50%;
      bottom: 50px;
      margin-top: 1rem;
      transform: translateX(-50%);

      .carousel-indicator {
        width: 8px;
        height: 8px;
        background-color: var(--teal);
        border-radius: 50%;
        transition: all 0.5s;
        cursor: pointer;
      }
      .carousel-indicator-active {
        width: 24px;
        background-color: var(--primary-color);
        border-radius: 120px;
      }
    }
  }
  .auth-content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .content-wrapper-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;

      .add-member-manually-btn {
        background: transparent;
        font-size: 16px;
        color: var(--dark-slate-gray);
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        &:hover {
          background: transparent !important;
        }
      }
      .add-member-manually-btn-icon {
        rotate: 90deg;
      }

      .content-back {
        padding: 10px;
        font-weight: 700;
      }

      .content-container {
        width: 300px;
        margin: auto;
      }
    }
  }
}

.float-button-container {
  position: fixed;
  bottom: 90px;
  right: 35px;
}
