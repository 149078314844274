// --> Created-by : thakkarshrey10
// --> Dated : 27/04/2024

// These are the global styles for InputOTP component from antd-input-otp.
// This is used as an otp component.
// These styles are imported in the main index.scss file which are available globally.

.otp-input-wrapper {
  width: 100%;
  border: 1px solid var(--prusian-blue);
  color: var(--prusian-blue);
  background-color: var(--alice-blue);
  overflow: hidden;
  border-radius: 12px;
  position: relative;

  .otp-input {
    border: none;
    background-color: var(--alice-blue);
    color: var(--prusian-blue);
    height: 40px;
    font-size: 12px;
    font-family: var(--font-family-nunito);
    padding: 0;
  }

  .ant-input-status-error {
    border: none !important;
    background-color: var(--alice-blue) !important;
  }

  .otp-input:hover {
    background-color: var(--alice-blue);
  }
  .otp-input:focus-visible {
    background-color: var(--alice-blue);
  }
}
.otp-input-wrapper:has(.ant-input-status-error) {
  border: 1px solid var(--postal-red);
}

.otp-submit-btn {
  width: 30px;
  aspect-ratio: 1;
  background-color: var(--alice-blue);
  border: none;
}

.otp-submit-btn:hover {
  background-color: var(--alice-blue) !important;
  border: 1px solid var(--prusian-blue) !important;
}
