.loading-container {
  background-color: var(--light-black);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1054;
}

.loading-image {
  animation: pulse 1s infinite ease-in-out alternate;
  width: 200px;
  aspect-ratio: 1;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.loading-gl-container {
  position: absolute; // Allow it to overlay over any container
  top: 50%; // Center vertically
  left: 50%; // Center horizontally
  transform: translate(-50%, -50%); // Adjust position based on the center
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-gl-image {
  animation: pulse 1s infinite ease-in-out alternate;
  width: 80px;
  height: 80px; // Ensures aspect ratio is 1:1 and is consistent
  max-width: 100%; // Responsive to container resizing
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
