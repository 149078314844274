.ant-tour {
  width: 300px;
  color: var(--pure-white);

  .ant-tour-arrow:before {
    background-color: var(--deep-navy-blue) !important;
  }

  .ant-tour-inner {
    background-color: var(--deep-navy-blue) !important;
    padding: 10px 16px 2px 16px !important;
  }

  .ant-tour-close .ant-tour-close-icon {
    color: var(--pure-white) !important;
  }

  .ant-tour-header {
    padding: 14px 0px !important;
    .ant-tour-title {
      .tour-title {
        h3 {
          padding: 0 16px;
        }
        .title-from {
          text-align: center;
          margin-bottom: 12px;
          border-bottom: 1px solid var(--steel-blue);
          width: 100%;
          padding-bottom: 12px;
        }
      }
    }
  }

  .ant-tour-description {
    margin-bottom: 14px !important;
  }

  .ant-tour-footer {
    border-top: 1px solid var(--steel-blue);
    margin: 8px 0 0 0 !important;
    display: flex;
    align-items: center;

    .ant-tour-buttons {
      display: flex;

      .ant-btn-primary,
      .ant-btn-default {
        padding: 10px;
        height: 36px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-tour-prev-btn {
        background-color: transparent;
        border: none;
        font-weight: 700;
        padding: 10px;
      }

      .ant-tour-next-btn {
        margin-inline-start: 15px !important;
      }
    }
  }
}
