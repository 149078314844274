.broadcast-widget-container {
  position: absolute;
  width: 400px;
  left: -200px;
  top: 45px;
  z-index: 1;
  overflow-y: auto;
  border-radius: 8px;
  background-color: var(--pure-white);
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.show-broadcast-widget-card {
  // display: block;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
  max-height: 500px;
}

.hide-broadcast-widget-card {
  // display: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  max-height: 0px;
}
