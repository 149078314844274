// --> Created-by : thakkarshrey10
// --> Dated : 27/04/2024

// These are the global styles for Notification component from Antd which is used as a toast message.
// They are applied because of the restriction of style changes in antd theme.js.
// These styles are imported in the main index.scss file which are available globally.

$notification-styles: (
  error: (
    'error-color': var(--error),
    'background-color': var(--pale-rose)
  ),
  success: (
    'error-color': var(--success),
    'background-color': var(--subtle-mint)
  )
);

@each $type, $data in $notification-styles {
  $error-color: map-get(
    $map: $data,
    $key: 'error-color'
  );
  $background-color: map-get(
    $map: $data,
    $key: 'background-color'
  );

  .ant-notification-notice-#{$type} {
    border: 1px solid $error-color;
    border-radius: 0.5rem;
    background-color: $background-color;

    .ant-notification-notice-message {
      color: $error-color !important;
    }

    .ant-notification-notice-description {
      color: $error-color !important;
    }
  }
}
