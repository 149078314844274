.navbar-container {
  box-shadow: var(--default-box-shadow);
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 1rem;
  z-index: 1051;
  height: fit-content;

  .broadcast-container {
    position: relative;
    cursor: pointer;
  }

  .notification-container {
    position: relative;
    cursor: pointer;
  }

  .profile-container {
    cursor: pointer;
    user-select: none;
    position: relative;
  }

  .profile-selection-dropdown-white {
    .ant-select-selection-item {
      color: var(--pure-white);
    }
    .ant-select-arrow {
      color: var(--pure-white);
    }
    .ant-select-selector input {
      color: var(--pure-white) !important;
    }
  }
}

.synapp-tour-dialog {
  .ant-modal-content {
    background-color: var(--dark-slate-gray);
    border-radius: 12px;
    user-select: none;
  }
  .ant-modal-close {
    color: var(--pure-white);
  }

  .ant-modal-close:hover {
    color: var(--pure-white);
  }
}

.main-navbar {
  background-color: var(--pure-white);
}

.primary-navbar {
  background-color: var(--primary-color);
}

.tour-container {
  display: block;
}

/* Media queries */
@media only screen and (max-width: 1200px) {
  .breadcrumb-column-container {
    display: none;
  }
}

@media (max-width: 768px) and (min-width: 575px) {
  .navbar-wrapper {
    padding-left: 4.2rem;
  }
}
/* Media queries */

@media (width < 768px) {
  .tour-container {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .profile-container {
    display: flex;
    justify-content: flex-end;
  }
}
/* Media queries */
